import React, { useState } from "react";
import axios from "axios";

const App = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://api.taginfosolutions.com/api/v1/login",
        {
          email,
          password,
        },
        {
          withCredentials: true, // Include credentials (cookies, authorization headers, etc.)
        }
      );
      console.log(res.data.message);
    } catch (error) {
      setError("Login failed. Please check your credentials.");
    }
  };

  const getUsers = () => {
    axios
      .get("https://api.taginfosolutions.com/api/v1/users", {
        withCredentials: true, // Ensures cookies are sent with the request
      })
      .then((res) => {
        console.log("zzzzzzzzzzz", res?.data);
        console.log("zzzzzzzzzzz", res?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            Remember Me
          </label>
        </div>
        <button type="submit">Login</button>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </form>
      <h3>Users</h3>
      <button onClick={getUsers}>Get Users</button>
    </div>
  );
};

export default App;
